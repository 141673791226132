import React, { useState } from 'react'
import './dropdownText.scss'
import IconArrayDown from './IconArrayDown'
import classnames from 'classnames'

interface IDropdownTextProps {
  label: JSX.Element
  text: JSX.Element | string
}

export const DropdownText: React.FC<IDropdownTextProps> = ({ label, text }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <div className={'dropdown-text'}>
      <button className={classnames('dropdown-text__container', open && 'active')} onClick={() => setOpen(!open)}>
        <h2 className={'dropdown-text__label'}>{label}</h2>
        <div className={classnames('dropdown__indicator', { open })}>
          <IconArrayDown />
        </div>
      </button>
      {open && (
        <div className={'dropdown-text__text-container normal-text dark-text-color'}>
          <div className={'inter-font'}>{text}</div>
        </div>
      )}
    </div>
  )
}
