import React from 'react'
import { BlockingBackground } from '../blockingBackground'
import { ContactForm } from 'components/ContactForm/contactForm'
import './contactUsWindow.scss'
interface IContactUsWindow {
  close(): void
}
export const ContactUsWindow: React.FC<IContactUsWindow> = ({ close }) => {
  return (
    <BlockingBackground>
      <div className={'blocking-window__contact-form'}>
        <ContactForm close={close} />
      </div>
    </BlockingBackground>
  )
}
