import React from 'react'
//import { ReactComponent as CubImg } from 'images/Cub.svg'
import CubImg from 'images/Cub.jpg'
import ArrowVertIcon from 'images/ArrowVertIcon'
import './workFlowBlock.scss'
import { BlockComponent } from 'components/BlockComponent/blockComponent'

export const WorkFlowBlock: React.FC = () => {
  const textList = [
    <>Thoroughly discussing your business goals to&nbsp;comprehend your needs</>,
    <>Collecting all the&nbsp;necessary information and&nbsp;requirements of&nbsp;your business case</>,
    <>Initial data processing and&nbsp;adaptation for&nbsp;our software system</>,
    <>
      Automated analysis of&nbsp;the&nbsp;data using our AI-based optimal design system which&nbsp;takes
      into&nbsp;account more than&nbsp;100&nbsp;000 design options
    </>,
    <>Post-processing the&nbsp;best options and&nbsp;validation by&nbsp;our experts</>,
    <>
      Providing documents of&nbsp;the&nbsp;final solutions containing all results, expert assessment,
      and&nbsp;conclusions
    </>,
  ]
  return (
    <div className={'work-flow-block__container container-transparent block-wrapper'}>
      <div className={'container__content container flex gap'}>
        <h1>Our Design Stages</h1>
        <div className={'work-flow-block__content'}>
          {textList.map((_text, index) => (
            <React.Fragment key={`component-${index}`}>
              <BlockComponent className={'center'} i={index + 1}>
                {<h3>{_text}</h3>}
              </BlockComponent>
              <div className={'arrow-block'}>
                <ArrowVertIcon />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
