import * as React from 'react'

function ArrowIcon(props) {
  return (
    <svg width='30' height='12' viewBox='0 0 30 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.8291 4.18647H1.90084C0.855378 4.18647 0 5.00578 0 6.00715C0 7.00853 0.855378 7.82784 1.90084 7.82784H22.8291V11.0869C22.8291 11.9062 23.8555 12.3067 24.4448 11.7241L29.7291 6.6444C30.0903 6.28026 30.0903 5.71585 29.7291 5.35171L24.4448 0.272001C23.8555 -0.310618 22.8291 0.108138 22.8291 0.909239V4.18647Z'
        fill='white'
      />
    </svg>
  )
}

export default ArrowIcon
