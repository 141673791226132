import React from 'react'
import './offerBlock.scss'
import { DropdownText } from 'components/DropdownText/dropdownText'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'

export const OfferBlock: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <div className={'offer-block__container container-transparent block-wrapper'}>
      <div className={'container__content container'}>
        <h1>What We Offer</h1>
        <div className={'offer-block__content'}>
          <div className={'normal-text dark-text-color'}>
            Our mission is&nbsp;to&nbsp;reduce mining infrastructure and&nbsp;logistics investment costs
            with&nbsp;innovative technologies and&nbsp;expertise. For&nbsp;this&nbsp;purpose, we’ve created
            an&nbsp;innovative software which&nbsp;provides automatic generation and&nbsp;analysis of&nbsp;more
            than&nbsp;100 000 solutions.
          </div>
          <DropdownText
            label={
              <>
                Cost-optimal design for&nbsp;the&nbsp;<span>logisticts</span>
              </>
            }
            text={
              <>
                Our team calculates the&nbsp;best options for&nbsp;cost-effective transportation of&nbsp;raw materials
                from&nbsp;the&nbsp;mine site to&nbsp;the&nbsp;point of&nbsp;sale. You get proposals of&nbsp;optimal
                logistics solutions based on&nbsp;your existing infrastructure and&nbsp;business goals.
              </>
            }
          />
          <DropdownText
            label={
              <>
                Cost-optimal field <span>reengineering</span>
              </>
            }
            text={
              <>
                We design the&nbsp;best options for&nbsp;reengineering and&nbsp;modernization of&nbsp;existing fields
                meeting modern requirements. We provide you with&nbsp;the&nbsp;optimal reengineering solutions
                and&nbsp;estimation of&nbsp;construction cost offers based on&nbsp;your business resources
                and&nbsp;goals.
              </>
            }
          />
          <DropdownText
            label={
              <>
                Cost-optimal <span>field infrastructure</span> design
              </>
            }
            text={
              <>
                We design the best options for&nbsp;cost-effective solutions for&nbsp;the&nbsp;early stages of&nbsp;new
                field development. As&nbsp;a&nbsp;result of&nbsp;our work, you get solution proposals
                for&nbsp;a&nbsp;new field infrastructure including roads, pipelines, power lines,
                and&nbsp;positioning&nbsp;of site facilities.
              </>
            }
          />
          <DropdownText
            label={
              <>
                Cost-optimal <span>railroads</span> design
              </>
            }
            text={
              <>
                Our experts design cost-effective and&nbsp;safe options for&nbsp;railroad building. We provide you
                with&nbsp;cost-effective railroad designs based on&nbsp;your goals and&nbsp;requirements.
              </>
            }
          />
          <Button
            onClick={() =>
              dispatch(
                setBlockingWindow({
                  type: 'ContactForm',
                }),
              )
            }
          >
            Order turnkey service
            <ArrowIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
