import React from 'react'
import './caseCard.scss'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
interface ICaseCardProps extends React.HTMLAttributes<HTMLElement> {
  header: string
  infoText: string
  linkPath: string
  navigateTo(value?: string): void
  children?: any
}
export const CaseCard: React.FC<ICaseCardProps> = ({ header, infoText, linkPath, navigateTo, children }) => {
  return (
    <div
      className={'case-card__container'}
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.83) 10.27%, rgba(0, 0, 0, 0.40) 33.17%, rgba(0, 0, 0, 0.00) 62.67%, rgba(0, 0, 0, 0.70) 80.27%), url(${children.props.src}) lightgray 50% / cover no-repeat`,
      }}
    >
      <div className={'case-card__header'}>{header}</div>
      <div className={'case-card__footer'}>
        <div className={'case-card__footer-text normal-text'}>{infoText}</div>{' '}
        {/*<Button mode={'fourth-style'} onClick={() => navigateTo(linkPath)}>*/}
        {/*  Learn more <ArrowIcon />*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}
