import React from 'react'
import './backgroundtextBlock.scss'
import classnames from 'classnames'

interface IBackgroundTextBlock extends React.HTMLAttributes<HTMLElement> {
  backgroundText: string
  children?: any
}
export const BackgroundTextBlock: React.FC<IBackgroundTextBlock> = ({ backgroundText, children, ...props }) => {
  return (
    <div className={classnames('background-text-block__container block-wrapper', props.className)}>
      <div className={'container__content'}>
        <div className={'background-text'}>{backgroundText}</div>
        <div className={'background-text-block__content'}>{children}</div>
      </div>
    </div>
  )
}
