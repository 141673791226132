// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-block__container {
  background-color: var(--web-primary-color);
}

.contact-block__header {
  color: var(--accent-color);
}

.contact-block__email {
  display: inline-flex;
  align-items: center;
  color: var(--white-color);
}

.contact-block__email button {
  width: min-content;
  height: min-content;
}

@media only screen and (min-device-width: 320px) {
  .contact-block__email {
    gap: 12px;
  }
}
@media only screen and (min-device-width: 768px) {
  .contact-block__email {
    gap: 12px;
  }
}
@media only screen and (min-device-width: 992px) {
  .contact-block__email {
    gap: 20px;
  }
}
@media only screen and (min-device-width: 1366px) {
  .contact-block__email {
    gap: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUsBlock/contactUsBlock.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,oBAAA;EACA,mBAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;AACF;AAEA;EACE;IACE,SAAA;EAAF;AACF;AAIA;EACE;IACE,SAAA;EAFF;AACF;AAKA;EACE;IACE,SAAA;EAHF;AACF","sourcesContent":[".contact-block__container {\n  background-color: var(--web-primary-color);\n}\n\n.contact-block__header {\n  color: var(--accent-color);\n}\n\n.contact-block__email {\n  display: inline-flex;\n  align-items: center;\n  color: var(--white-color);\n}\n\n.contact-block__email button {\n  width: min-content;\n  height: min-content;\n}\n\n@media only screen and (min-device-width: 320px) {\n  .contact-block__email {\n    gap: 12px;\n  }\n}\n\n@media only screen and (min-device-width: 768px) {\n  .contact-block__email {\n    gap: 12px;\n  }\n\n}\n\n@media only screen and (min-device-width: 992px) {\n  .contact-block__email {\n    gap: 20px;\n  }\n}\n\n@media only screen and (min-device-width: 1366px) {\n  .contact-block__email {\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
