// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vanta-canvas {
  width: 100% !important;
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/landingPage.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;AACF","sourcesContent":[".vanta-canvas{\n  width: 100% !important;\n  height: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
