// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-button {
  display: flex;
  align-items: center;
  padding: 0 24px;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.28px;
  cursor: pointer;
}
.h-button:hover {
  color: var(--white-color);
}
.h-button:active {
  color: var(--text-color-2);
}`, "",{"version":3,"sources":["webpack://./src/components/HButton/hButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,0BAAA;AAEJ","sourcesContent":[".h-button{\n  display: flex;\n  align-items: center;\n  padding: 0 24px;\n  text-transform: uppercase;\n  color: var(--secondary-text-color);\n  font-size: 18px;\n  font-weight: 500;\n  letter-spacing: 0.28px;\n  cursor: pointer;\n  &:hover {\n    color: var(--white-color);\n  }\n  &:active {\n    color: var(--text-color-2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
