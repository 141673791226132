// @ts-ignore
import NET from 'vanta/dist/vanta.net.min'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
export const Net: React.FC = () => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: myRef.current,
          mouseControls: true,
          color: '#414141',
          backgroundColor: '#1b1b1b',
          touchControls: true,
          gyroControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
        }),
      )
    }
    return () => {
      if (vantaEffect) {
        // @ts-ignore
        vantaEffect.destroy()
      }
    }
  }, [vantaEffect])

  return <div className={'background'} ref={myRef}></div>
}
