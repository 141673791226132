import * as React from 'react'

function CloseIcon(props) {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.4501 9.06508C26.8651 8.48008 25.9201 8.48008 25.3351 9.06508L18.0001 16.3851L10.6651 9.05008C10.0801 8.46508 9.13508 8.46508 8.55008 9.05008C7.96508 9.63508 7.96508 10.5801 8.55008 11.1651L15.8851 18.5001L8.55008 25.8351C7.96508 26.4201 7.96508 27.3651 8.55008 27.9501C9.13508 28.5351 10.0801 28.5351 10.6651 27.9501L18.0001 20.6151L25.3351 27.9501C25.9201 28.5351 26.8651 28.5351 27.4501 27.9501C28.0351 27.3651 28.0351 26.4201 27.4501 25.8351L20.1151 18.5001L27.4501 11.1651C28.0201 10.5951 28.0201 9.63508 27.4501 9.06508Z'
        fill='#1B1B1B'
      />
    </svg>
  )
}

export default CloseIcon
