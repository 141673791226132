// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocking-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  gap: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/BlockingWindow/blockingWindow.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF","sourcesContent":[".blocking-container{\n  position: absolute;\n  z-index: 10;\n  width: 100%;\n  gap: 24px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
