import { HButton } from '../HButton/hButton'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
import { ReactComponent as LogoIcon } from 'images/LogoIconFooter.svg'
import React from 'react'
import { BlockType } from 'Pages/types'
import MenuIcon from 'images/MenuIcon'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
interface IMenuContentProps {
  setScrollTo(value: BlockType): void
  setVisibility(value: boolean): void
}
export const MenuContent: React.FC<IMenuContentProps> = ({ setScrollTo, setVisibility }) => {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(
      setBlockingWindow({
        type: 'ContactForm',
      }),
    )
    setVisibility(false)
  }
  return (
    <>
      <div className={'menu__content'}>
        <div className={'menu__content-header'}>
          <MenuIcon />
          <LogoIcon />
        </div>
        <div className={'menu__content-body'}>
          <HButton
            onClick={() => {
              setScrollTo('ABOUT')
              setVisibility(false)
            }}
          >
            about
          </HButton>
          <HButton
            onClick={() => {
              setScrollTo('CASES')
              setVisibility(false)
            }}
          >
            cases
          </HButton>
          <HButton
            onClick={() => {
              setScrollTo('DEMO')
              setVisibility(false)
            }}
          >
            demo
          </HButton>
          <HButton
            onClick={() => {
              setScrollTo('TECHNOLOGIES')
              setVisibility(false)
            }}
          >
            technologies
          </HButton>
          <HButton
            onClick={() => {
              setScrollTo('CONTACTS')
              setVisibility(false)
            }}
          >
            contacts
          </HButton>
          <div className={'menu__content-body-btn'}>
            <Button onClick={onClick}>
              Contact us <ArrowIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
