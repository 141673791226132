// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer-block__container {
  background-color: var(--white-color);
}
.offer-block__container h1 {
  color: var(--web-primary-color);
}

.offer-block__text {
  color: var(--secondary-text-color);
}

.offer-block__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-device-width: 320px) {
  .offer-block__container h1 {
    margin-bottom: 12px;
  }
  .offer-block__content {
    gap: 20px;
  }
}
@media only screen and (min-device-width: 768px) {
  .offer-block__container h1 {
    margin-bottom: 20px;
  }
  .offer-block__content {
    gap: 44px;
  }
}
@media only screen and (min-device-width: 992px) {
  .offer-block__container h1 {
    margin-bottom: 32px;
  }
  .offer-block__content {
    gap: 44px;
  }
}
@media only screen and (min-device-width: 1366px) {
  .offer-block__container h1 {
    margin-bottom: 36px;
  }
  .offer-block__content {
    gap: 52px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/components/OfferBlock/offerBlock.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AACE;EACE,+BAAA;AACJ;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;;AAIA;EAEI;IACE,mBAAA;EAFJ;EAKA;IACE,SAAA;EAHF;AACF;AAMA;EAEI;IACE,mBAAA;EALJ;EAQA;IACE,SAAA;EANF;AACF;AASA;EAEI;IACE,mBAAA;EARJ;EAWA;IACE,SAAA;EATF;AACF;AAYA;EAEI;IACE,mBAAA;EAXJ;EAcA;IACE,SAAA;EAZF;AACF","sourcesContent":[".offer-block__container {\n  background-color: var(--white-color);\n\n  h1 {\n    color: var(--web-primary-color);\n  }\n\n}\n\n.offer-block__text {\n  color: var(--secondary-text-color);\n}\n\n.offer-block__content {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n@media only screen and (min-device-width: 320px) {\n  .offer-block__container {\n    h1 {\n      margin-bottom: 12px;\n    }\n  }\n  .offer-block__content {\n    gap: 20px;\n  }\n}\n\n@media only screen and (min-device-width: 768px) {\n  .offer-block__container {\n    h1 {\n      margin-bottom: 20px;\n    }\n  }\n  .offer-block__content {\n    gap: 44px;\n  }\n}\n\n@media only screen and (min-device-width: 992px) {\n  .offer-block__container {\n    h1 {\n      margin-bottom: 32px;\n    }\n  }\n  .offer-block__content {\n    gap: 44px;\n  }\n}\n\n@media only screen and (min-device-width: 1366px) {\n  .offer-block__container {\n    h1 {\n      margin-bottom: 36px;\n    }\n  }\n  .offer-block__content {\n    gap: 52px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
