import React from 'react'
import './blockDemo.scss'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'

export const ExploreDemo: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <div className={'demo-block__container block-wrapper container-transparent'}>
      <div className={'container__content container flex gap'}>
        <h1>Explore Our Demo</h1>
        <div>
          <div className={'explore-demo__content'}>
            <div className={'explore-demo__img1'} />
            <div className={'explore-demo__img2'} />
            <div className={'explore-demo__img3'} />
          </div>
          <div className={'normal-text dark-text-color'}>
            Check out our calculations of&nbsp;railroad, road, and&nbsp;pipeline
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              window.location.href = 'https://xsors.com/xgeo/demo/'
            }}
          >
            Explore Our Demo <ArrowIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
