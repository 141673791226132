import './contactForm.scss'
import React from 'react'
import TextInput from 'components/Input/textInput'
import TextArea from 'components/TextArea/textArea'
import Button from 'components/Button/button'
import CloseIcon from 'images/CloseIcon'
import { setBlockingWindow } from '../../store/commonSlice'
import { useDispatch } from 'react-redux'
interface IContactForm {
  close?(): void
}
export const ContactForm: React.FC<IContactForm> = ({ close }) => {
  const dispatch = useDispatch()
  const [name, setName] = React.useState<string>()
  const [email, setEmail] = React.useState<string>()
  const [industry, setIndustry] = React.useState<string>()
  const [company, setCompany] = React.useState<string>()
  const [comment, setComment] = React.useState<string>()
  const [nameError, setNameError] = React.useState<boolean>(false)
  const [emailError, setEmailError] = React.useState<boolean>(false)

  const onInputName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError) setNameError(false)
    setName(e.target.value)
  }

  const onInputEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) setEmailError(false)
    setEmail(e.target.value)
  }

  const onInputIndustry = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndustry(e.target.value)
  }

  const onInputCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value)
  }

  const onInputComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const onSubmitForm = () => {
    if (!name || !email) {
      if (!name) setNameError(true)
      if (!email) setEmailError(true)
      return
    }
    fetch('/api/contact', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, industry, company, comment }),
    }).then()
    close && close()
    dispatch(
      setBlockingWindow({
        type: 'DoneWindow',
      }),
    )
  }

  return (
    <div className={'contact-form__container'}>
      <h1 className={'contact-form__container-header'}>
        Contact us{' '}
        {close && (
          <button onClick={close}>
            <CloseIcon />
          </button>
        )}
      </h1>
      <div className={'contact-form__info-text normal-text inter-font'}>
        Any questions? Contact us and&nbsp;our experts will reach&nbsp;out to you
      </div>
      <div className={'contact-form__content'}>
        <TextInput label={'Name*'} error={nameError} onInput={onInputName} isRequired={true} />
        <TextInput label={'Email*'} error={emailError} onInput={onInputEmail} isRequired={true} />
        <TextInput label={'Industry'} onInput={onInputIndustry} />
        <TextInput label={'Company'} onInput={onInputCompany} />
        <TextArea label={'Comment'} maxlength={1500} spellCheck={false} onInput={onInputComment} />
        <div>
          <Button onClick={onSubmitForm}>Submit</Button>
        </div>
      </div>
    </div>
  )
}
