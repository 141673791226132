import * as React from 'react'

import classnames from 'classnames'

import './style.scss'
import { useEffect, useState } from 'react'

interface IInput extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  onEnter?(): void
  classNames?: string
  placeholder?: string
  children?: any
  maxlength?: number
}

const TextArea: React.FC<IInput> = ({ onEnter, classNames, maxlength, ...props }) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [focused, setFocused] = React.useState<boolean>(false)
  const [, setVisibility] = useState(false)
  const [charCount, setCharCount] = useState<number>(0)

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation()
    if (['Escape'].includes(e.key)) {
      ref?.current?.blur()
    }
    /* if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    } */
    return false
  }

  const onBlur = () => {
    if (typeof onEnter === 'function') {
      onEnter()
    }
    setFocused(false)
  }
  const onFocus = () => {
    setFocused(true)
  }
  /* const blur = () => {
    ref.current?.blur()
  }
  useEffect(() => {
    if (focused) {
      window.addEventListener('resize', blur)
    }
    setVisibility(focused)
    return () => {
      window.removeEventListener('resize', blur)
    }
  }, [focused]) */

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!inputRef?.current?.contains(event.target)) {
        setFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [focused])

  const onInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onInput) props.onInput(e)
    setCharCount(e.target.value.length)
  }

  return (
    <>
      <div className={classnames('input-wrapper')} ref={inputRef}>
        {props.label && (
          <div className={'input-wrapper__label label-text'}>
            <div className={'field-label-text label-text'}>{props.label}</div>
          </div>
        )}
        <div
          className={classnames('unit-wrapper textarea label-text', props.disabled && 'disabled', classNames, {
            focused,
          })}
        >
          <textarea
            ref={ref as any}
            {...props}
            onKeyDown={onKeyDownHandler as any}
            onFocus={onFocus}
            onBlur={onBlur}
            onInput={onInputHandler}
            placeholder={props.placeholder}
          />
        </div>
        <div className={'input-wrapper__label'}>
          <div className={'field-label-text label-text'}>{`${charCount}/${maxlength}`}</div>
        </div>
      </div>
    </>
  )
}

export default TextArea
