import React from 'react'
import './projectCard.scss'
// import CubImage from 'images/Cub.jpg'
// import Button from 'components/Button/button'
// import ArrowIcon from 'images/ArrowIcon'
interface IProjectCardProps extends React.HTMLAttributes<HTMLElement> {
  name: string
  info: JSX.Element | string
  pathLink: string
  navigateTo(value: string): void
  children?: any
}
export const ProjectCard: React.FC<IProjectCardProps> = ({ name, info, pathLink, navigateTo, children }) => {
  return (
    <div className={'project-card__container'}>
      <div
        className={'project-card__image'}
        style={{ background: `url(${children.props.src}) lightgray 50% / cover no-repeat` }}
      />
      <div className={'project-card__footer'}>
        <div>
          <div className={'project-card__name'}>{name}</div>
          <div className={'project-card__info normal-text dark-text-color'}>{info}</div>
        </div>
        {/*<Button mode={'third-style'} onClick={() => navigateTo(pathLink)}>*/}
        {/*  Explore*/}
        {/*  <ArrowIcon />*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}
