import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IBlockingWindow, ICommonSlice, IScrollTo } from './types'

const initialState: ICommonSlice = {
  isMobile: false,
  isDesktop: false,
  isTablet: false,
  showHeaderButtons: false,
  blockingWindow: {
    type: undefined,
    isConfirm: false,
  },
  scrollTo: {
    blockType: undefined,
  },
}
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload
    },
    setIsTablet: (state, action: PayloadAction<boolean>) => {
      state.isTablet = action.payload
    },
    setIsDesktop: (state, action: PayloadAction<boolean>) => {
      state.isDesktop = action.payload
    },
    setShowHeaderButtons: (state, action: PayloadAction<boolean>) => {
      state.showHeaderButtons = action.payload
    },
    setBlockingWindow: (state, action: PayloadAction<IBlockingWindow>) => {
      state.blockingWindow = action.payload
    },
    resetBlockingWindow: (state) => {
      state.blockingWindow = initialState.blockingWindow
    },
    setScrollTo: (state, action: PayloadAction<IScrollTo>) => {
      state.scrollTo = action.payload
    },
  },
})

export const {
  setIsMobile,
  setIsTablet,
  setIsDesktop,
  setBlockingWindow,
  resetBlockingWindow,
  setScrollTo,
  setShowHeaderButtons,
} = commonSlice.actions

const selectSelf = (state: RootState) => state.common

export const selectIsMobile = createSelector(selectSelf, (common) => common.isMobile)
export const selectIsTablet = createSelector(selectSelf, (common) => common.isTablet)
export const selectIsDesktop = createSelector(selectSelf, (common) => common.isDesktop)
export const selectShowHeaderButtons = createSelector(selectSelf, (common) => common.showHeaderButtons)
export const selectBlockingWindow = createSelector(selectSelf, (common) => common.blockingWindow)
export const selectScrollTo = createSelector(selectSelf, (common) => common.scrollTo)

export default commonSlice.reducer
