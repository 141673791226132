import React from 'react'
import TextAreaInput from '../TextArea'
interface ITextInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  classNames?: string
  label?: string
  value?: string
  setValue?(value: string, label?: string): void
  maxlength?: number
  placeholder?: string
}

const TextArea: React.FC<ITextInputProps> = ({
  value,
  classNames,
  disabled,
  setValue,
  label,
  maxlength = 30,
  placeholder = '',
  ...props
}) => {
  const [valueState, setValueState] = React.useState<string | undefined>('')

  React.useEffect(() => {
    if (value) setValueState(value.toString().trim())
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValueState(e.target.value.slice(0, maxlength - 1))
  }
  const onEnterHandler = () => {
    if (valueState?.trim().length) {
      setValue && setValue(valueState)
    } else setValueState(value)
  }

  return (
    <TextAreaInput
      {...props}
      label={label}
      value={valueState}
      onChange={onChangeHandler}
      classNames={classNames}
      disabled={disabled}
      onEnter={onEnterHandler}
      placeholder={placeholder}
      maxlength={maxlength}
    />
  )
}

export default TextArea
