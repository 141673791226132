import React from 'react'
import './secondBlock.scss'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
import { BackgroundTextBlock } from 'components/BackgroundTextBlock/backgroundtextBlock'
export const SecondBlock: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <BackgroundTextBlock className={'second-block__container container-transparent'} backgroundText={'xsors'}>
      <div className={'title2-text'}>
        Discovering optimal solutions for business challenges through expertise in mathematics, physics, programming,
        and domain-specific knowledge
      </div>
      <Button
        mode={'third-style'}
        onClick={() =>
          dispatch(
            setBlockingWindow({
              type: 'ContactForm',
            }),
          )
        }
      >
        Contact us <ArrowIcon />
      </Button>
    </BackgroundTextBlock>
  )
}
