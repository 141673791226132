export const isDesktop = () => {
  return window.screen.width > 992
}
export const isTablet = () => {
  return window.screen.width <= 992 && window.screen.width >= 768
}

export const isMobile = () => {
  return window.screen.width < 768
}

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent)
}
