// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cases-block__container {
  background-color: var(--web-primary-color);
}
.cases-block__container h1 {
  color: var(--white-color);
}

.cases-block__content {
  display: grid;
  width: 100%;
}

@media only screen and (min-device-width: 320px) {
  .cases-block__container > h1 {
    margin-bottom: 20px;
  }
  .cases-block__content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 320px);
    row-gap: 20px;
  }
}
@media only screen and (min-device-width: 768px) {
  .cases-block__container > h1 {
    margin-bottom: 44px;
  }
  .cases-block__content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 380px);
    row-gap: 36px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cases-block__container > h1 {
    margin-bottom: 52px;
  }
  .cases-block__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 560px 380px;
    row-gap: 36px;
    column-gap: 36px;
  }
  .cases-block__content :first-child {
    grid-column-start: span 3;
  }
}
@media only screen and (min-device-width: 1366px) {
  .cases-block__container > h1 {
    margin-bottom: 52px;
  }
  .cases-block__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 680px 440px;
    row-gap: 36px;
    column-gap: 36px;
  }
  .cases-block__content :first-child {
    grid-column-start: span 3;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/components/CasesBlock/casesBlock.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,aAAA;EACA,WAAA;AAAF;;AAIA;EACE;IACE,mBAAA;EADF;EAGA;IACE,0BAAA;IACA,oCAAA;IACA,aAAA;EADF;AACF;AAIA;EACE;IACE,mBAAA;EAFF;EAIA;IACE,0BAAA;IACA,oCAAA;IACA,aAAA;EAFF;AACF;AAKA;EACE;IACE,mBAAA;EAHF;EAKA;IACE,kCAAA;IACA,+BAAA;IACA,aAAA;IACA,gBAAA;EAHF;EAIE;IACA,yBAAA;EAFF;AACF;AAMA;EACE;IACE,mBAAA;EAJF;EAMA;IACE,kCAAA;IACA,+BAAA;IACA,aAAA;IACA,gBAAA;EAJF;EAKE;IACA,yBAAA;EAHF;AACF","sourcesContent":[".cases-block__container {\n  background-color: var(--web-primary-color);\n\n  h1 {\n    color: var(--white-color);\n  }\n}\n\n.cases-block__content {\n  display: grid;\n  width: 100%;\n\n}\n\n@media only screen and (min-device-width: 320px) {\n  .cases-block__container>h1{\n    margin-bottom: 20px;\n  }\n  .cases-block__content {\n    grid-template-columns: 1fr;\n    grid-template-rows: repeat(4, 320px);\n    row-gap: 20px;\n  }\n}\n\n@media only screen and (min-device-width: 768px) {\n  .cases-block__container>h1{\n    margin-bottom: 44px;\n  }\n  .cases-block__content {\n    grid-template-columns: 1fr;\n    grid-template-rows: repeat(4, 380px);\n    row-gap: 36px;\n  }\n}\n\n@media only screen and (min-device-width: 992px) {\n  .cases-block__container>h1{\n    margin-bottom: 52px;\n  }\n  .cases-block__content {\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 560px 380px;\n    row-gap: 36px;\n    column-gap: 36px;\n    :first-child {\n    grid-column-start: span 3;\n  }\n  }\n}\n\n@media only screen and (min-device-width: 1366px) {\n  .cases-block__container>h1{\n    margin-bottom: 52px;\n  }\n  .cases-block__content {\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 680px 440px;\n    row-gap: 36px;\n    column-gap: 36px;\n    :first-child {\n    grid-column-start: span 3;\n  }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
