import React from 'react'
import { selectBlockingWindow, resetBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import './blockingWindow.scss'
import { BlockingBackground } from './blockingBackground'
import { ContactUsWindow } from './contactUsWindow/contactUsWindow'
import { DoneWindow } from './doneWindow/doneWindow'

export const BlockingWindow: React.FC = () => {
  const blockingWindow = useSelector(selectBlockingWindow)
  const dispatch = useDispatch()
  const closeWindow = () => {
    dispatch(resetBlockingWindow())
  }

  if (blockingWindow.type === 'Background') return <BlockingBackground />
  if (blockingWindow.type === 'ContactForm') return <ContactUsWindow close={closeWindow} />
  if (blockingWindow.type === 'DoneWindow') return <DoneWindow close={closeWindow} />
  return <></>
}
