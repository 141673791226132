import './contactUsBlock.scss'
import React from 'react'
import { CopyToClipboardButton } from '../CopyToClipboardButton/copyToClipboardButton'
import { ContactForm } from 'components/ContactForm/contactForm'

export const ContactUsBlock: React.FC = () => {
  const copy = () => {
    navigator.clipboard.writeText('info@xsors.com')
  }
  return (
    <div className={'contact-block__container container-transparent block-wrapper'}>
      <div className={'container__content container flex gap'}>
        <ContactForm />
        {/*<h1 className={'contact-block__header'}>Contact us</h1>
        <div className={'normal-text light-text-color'}>
          Any questions? Contact us and our experts will reach out to you.
        </div>
        <div className={'contact-block__email title3-text'}>
          info@xsors.com <CopyToClipboardButton textToCopy={'info@xsors.com'} />
        </div>*/}
      </div>
    </div>
  )
}
