import { useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'

export const useInitPage = () => {
  useEffect(() => {
    scroll.scrollToTop({ duration: 0 })
    const options = {
      // родитель целевого элемента - область просмотра
      root: null,
      // без отступов
      rootMargin: '0px',
      // процент пересечения - 0.3 блока
      threshold: 0.1,
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('container-transparent')
          if (entry.boundingClientRect.y > 0) entry.target.classList.add('container-animation')
        }
      })
    }, options)
    const targets = document.querySelectorAll('.container-transparent')
    targets.forEach((target) => {
      observer.observe(target)
    })
  }, [])
}
