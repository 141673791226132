import React, { useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Header } from 'components/Header/header'
import './landingPage.scss'
import { CommonInfo } from './components/CommonInfo/commonInfo'
import { SecondBlock } from './components/SecondBlock/secondBlock'
import { ThirdBlock } from './components/ThirdBlock/thirdBlock'
import { OfferBlock } from './components/OfferBlock/offerBlock'
import { CasesBlock } from './components/CasesBlock/casesBlock'
import { WorkFlowBlock } from './components/WorkFlowBlock/workFlowBlock'
import { TechnologiesBlock } from './components/TechnologiesBlock/technologiesBlock'
import { ContactUsBlock } from 'components/ContactUsBlock/contactUsBlock'
import { Footer } from 'components/Footer/footer'
import { useDispatch, useSelector } from 'react-redux'
import { resetBlockingWindow, selectBlockingWindow, selectScrollTo, setScrollTo } from 'store/commonSlice'
import { BlockType } from '../types'
import { useInitPage } from '../../hooks/useInitPage'
import { ExploreDemo } from './components/DemoBlock/blockDemo'
export const LandingPage: React.FC = () => {
  const scrollTo = useSelector(selectScrollTo)
  const blockingWindow = useSelector(selectBlockingWindow)
  const dispatch = useDispatch()

  useInitPage()

  useEffect(() => {
    let block
    switch (scrollTo.blockType) {
      case 'ABOUT':
        block = document.getElementsByClassName('third-block__container')[0]
        break
      case 'CASES':
        block = document.getElementsByClassName('cases-block__container')[0]
        break
      case 'DEMO':
        block = document.getElementsByClassName('demo-block__container')[0]
        break
      case 'TECHNOLOGIES':
        block = document.getElementsByClassName('technologies-block__container')[0]
        break
      case 'CONTACTS':
        block = document.getElementsByClassName('contact-block__container')[0]
    }
    if (block) {
      // @ts-ignore
      scroll.scrollTo(block?.offsetTop, {
        duration: 0,
      })
    }
  }, [scrollTo])

  /*useEffect(() => {
    if (blockingWindow.type === 'ContactForm') {
      dispatch(setScrollTo({ blockType: 'CONTACTS' }))
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow])*/

  const setScrollToValue = (value?: BlockType) => {
    dispatch(
      setScrollTo({
        blockType: value,
      }),
    )
  }
  return (
    <>
      <Header setScrollTo={setScrollToValue} />
      <div className={'main__content'}>
        <CommonInfo />
        <SecondBlock />
        <ThirdBlock />
        <OfferBlock />
        <CasesBlock />
        <ExploreDemo />
        <WorkFlowBlock />
        <TechnologiesBlock />
        <ContactUsBlock />
        <Footer setScrollTo={setScrollToValue} />
      </div>
    </>
  )
}
