import React, { useEffect, useState } from 'react'
import './commonInfo.scss'
import Button from 'components/Button/button'
import ArrowIcon from 'images/ArrowIcon'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsMobile, setBlockingWindow } from 'store/commonSlice'
import { Net } from '../../../../Net'
export const CommonInfo: React.FC = () => {
  const isMobile = useSelector(selectIsMobile)
  const dispatch = useDispatch()
  const [top, setTop] = useState<number>()
  useEffect(() => {
    if (!top) {
      const header = document.getElementsByClassName('header')[0]
      header && setTop(header.clientHeight)
    }
  }, [])
  const textList = ['Oil, and Gas mining', 'Logistics', 'Rail Roads', 'Industial zones design']
  return (
    <div className={classnames('common-info__container block-wrapper', isMobile && 'mobile')}>
      {!isMobile && <Net />}
      <div className={'container__content'}>
        <div className={'common-info__content'}>
          <div className={'text-block__first'}>
            {textList.map((title, index) => (
              <React.Fragment key={`title-${index}`}>
                <div>{title}</div> <div className={'circle'} />
              </React.Fragment>
            ))}
          </div>
          <div className={'text-block__second title1-text'}>
            Consulting services for <span>improving effectiveness of</span> extractive industries
          </div>
          <div className={'normal-text light-text-color inter-font'}>
            AI, Machine learning, Digital transformation, Optimization
          </div>
          <Button
            onClick={() => {
              dispatch(
                setBlockingWindow({
                  type: 'ContactForm',
                }),
              )
            }}
          >
            Contact us <ArrowIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
