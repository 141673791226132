import React from 'react'
import './thirdBlock.scss'
import { DarkBlock } from 'components/DarkBlock/darkBlock'

export const ThirdBlock: React.FC = () => {
  return (
    <DarkBlock className={'third-block__container container-transparent block-wrapper'}>
      <div className={'container__content container'}>
        <h1>Metrics That Matter</h1>
        <div className={'dark-block__content'}>
          <div className={'main-text'}>
            <span className={'inter-font'}>30</span> years
            <div className={'normal-text light-text-color'}>of digital experience </div>
          </div>
          <div className={'main-text'}>
            <span className={'inter-font'}>10</span> billion $
            <div className={'sub-text normal-text'}>
              saved by our clients
              <br />
              with our technologies{' '}
            </div>
          </div>
          <div className={'main-text'}>
            <span className={'inter-font'}>100</span>+<div className={'sub-text normal-text'}>projects</div>
          </div>
          <div className={'main-text'}>
            Top <span className={'inter-font'}>15</span>
            <div className={'sub-text normal-text'}>
              clients from the oil-
              <br />
              mining industry
            </div>
          </div>
          <div className={'main-text'}>
            <span className={'inter-font'}>500</span>+<div className={'sub-text normal-text'}>experts</div>
          </div>
        </div>
      </div>
    </DarkBlock>
  )
}
