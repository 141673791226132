import React, { createContext, useRef, useState } from 'react'
import classnames from 'classnames'
import './MenuButton.scss'
import MenuIcon from 'images/MenuIcon'
import { useDispatch } from 'react-redux'
import { resetBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { MenuContent } from '../Header/menuContent'
import { BlockType } from '../../Pages/types'

interface IMenuButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  setScrollTo(value: BlockType): void
}

export const MenuButton: React.FC<IMenuButton> = ({ text, setScrollTo, ...props }) => {
  const [menuVisibility, setMenuVisibility] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const onClickDotsButton = (e: any) => {
    e.stopPropagation()
    setMenuVisibility(true)
  }
  const handleClickOutside = (event: any) => {
    if (!menuRef?.current?.contains(event.target)) {
      try {
        event.target.click()
      } catch (e) {
        console.error(e)
      }
      setMenuVisibility(false)
    }
  }
  React.useEffect(() => {
    if (menuVisibility) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuVisibility])
  // @ts-ignore
  return (
    <>
      <div className={'menu-button__container'}>
        <button
          className={classnames('menu-button', menuVisibility ? 'visible' : 'hidden')}
          onClick={onClickDotsButton}
          disabled={menuVisibility}
          {...props}
        >
          {text}
          <MenuIcon className={classnames('menu-img')} />
        </button>
      </div>
      {menuVisibility && (
        <>
          <div className={'blocking-background'} />
          <div className={classnames('popup-menu__container', menuVisibility)} ref={menuRef}>
            <MenuContent setScrollTo={setScrollTo} setVisibility={setMenuVisibility} />
          </div>
        </>
      )}
    </>
  )
}
