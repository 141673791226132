import React from 'react'
import './blockComponent.scss'
import CubImg from 'images/Cub.jpg'
import classNames from 'classnames'

interface IBlockComponent extends React.HTMLAttributes<HTMLElement> {
  i?: number
  children?: any
}
export const BlockComponent: React.FC<IBlockComponent> = ({ i, children, ...props }) => {
  return (
    <div className={classNames('block-component__view', props.className)}>
      <div className={'block-component__marker-container'}>
        <div className={'block-component__marker'}>
          <h3 className={'marker inter-font'}>{i}</h3>
        </div>
      </div>
      <div className={'block-component__view-text'}>{children}</div>
    </div>
  )
}
