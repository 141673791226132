import { useEffect } from 'react'
import { isDesktop, isMobile, isTablet } from 'utils'
import { useDispatch } from 'react-redux'
import { setIsDesktop, setIsMobile, setIsTablet, setShowHeaderButtons } from 'store/commonSlice'

export const useCheckDeviceType = () => {
  const dispatch = useDispatch()
  const checkDeviceType = () => {
    dispatch(setIsMobile(isMobile()))
    dispatch(setIsTablet(isTablet()))
    dispatch(setIsDesktop(isDesktop()))
    dispatch(setShowHeaderButtons(window.screen.width >= 1250))
  }
  useEffect(() => {
    checkDeviceType()
    window.addEventListener('orientationchange', checkDeviceType)
    window.addEventListener('resize', checkDeviceType)
    return () => {
      window.removeEventListener('orientationchange', checkDeviceType)
      window.removeEventListener('resize', checkDeviceType)
    }
  }, [])
}
