import * as React from 'react'

import classnames from 'classnames'

import './style.scss'
import { useEffect, useState } from 'react'

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  onEnter?(): void
  classNames?: string
  placeholder?: string
  children?: any
  error?: boolean
  isRequired?: boolean
}

const Input: React.FC<IInput> = ({ onEnter, classNames, error, isRequired, ...props }) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [focused, setFocused] = React.useState<boolean>(false)
  const [visible, setVisibility] = useState(false)
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (['Enter', 'Escape'].includes(e.key)) {
      ref?.current?.blur()
    }
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    }
    return false
  }

  const onBlur = () => {
    if (typeof onEnter === 'function') {
      onEnter()
    }
    setFocused(false)
  }
  const onFocus = () => {
    setFocused(true)
  }
  /* const blur = () => {
    ref.current?.blur()
  }
  useEffect(() => {
    if (focused) {
      window.addEventListener('resize', blur)
    }
    setVisibility(focused)
    return () => {
      window.removeEventListener('resize', blur)
    }
  }, [focused]) */

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!inputRef?.current?.contains(event.target)) {
        setFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [focused])

  return (
    <>
      <div className={classnames('input-wrapper')} ref={inputRef}>
        {props.label && (
          <div className={'input-wrapper__label'}>
            <div className={'field-label-text label-text'}>{props.label}</div>
            {isRequired && error && <div className={'field-label-text label-text required-text'}>Required</div>}
          </div>
        )}
        <div
          className={classnames('unit-wrapper input label-text', props.disabled && 'disabled', { error }, classNames, {
            focused,
          })}
        >
          <input
            ref={ref}
            {...props}
            onKeyDown={onKeyDownHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={props.placeholder}
          />
        </div>
      </div>
    </>
  )
}

export default Input
