import React from 'react'

import ReactDOM from 'react-dom/client'
import './index.scss'
import './local-styles.scss'
import './fonts.scss'
import { Provider } from 'react-redux'
import store from './store/configureStore'
import App from './App'
import { BlockingWindow } from 'components/BlockingWindow/blockingWindow'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BlockingWindow />
    <App />
  </Provider>,
)
