import React from 'react'

interface IBlockingBackground extends React.HTMLAttributes<HTMLElement> {
  children?: any
}
export const BlockingBackground: React.FC<IBlockingBackground> = ({ children }) => {
  return (
    <div className={'blocking-background'}>{children && <div className={'blocking-container'}>{children}</div>}</div>
  )
}
