import './hButton.scss'
import React from 'react'
interface IHButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any
}
export const HButton: React.FC<IHButton> = ({ children, ...props }) => {
  return (
    <button {...props} className={'h-button inter-font'}>
      {children}
    </button>
  )
}
