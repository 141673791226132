import React from 'react'
import { BlockingBackground } from '../blockingBackground'
import './doneWindow.scss'

interface IDoneWindow {
  close(): void
}

export const DoneWindow: React.FC<IDoneWindow> = ({ close }) => {
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  React.useEffect(() => {
    timerId.current = setInterval(() => {
      clearInterval(timerId.current)
      close()
    }, 2300)
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
      }
    }
  }, [])

  return (
    <BlockingBackground>
      <div className='success-checkmark'>
        <div className='success-checkmark__check-icon'>
          <svg className={'success-checkmark__circle-svg'} height='102' width='102' viewBox='0 0 102 102' fill='none'>
            <circle
              className='success-checkmark__circle'
              cx='51'
              cy='51'
              r='49'
              stroke='#ffffff'
              strokeWidth='4'
              fillOpacity='0'
            />
          </svg>
          <span className='success-checkmark__icon-line line-tip' />
          <span className='success-checkmark__icon-line line-long' />
          <div className='success-checkmark__mark' />
        </div>
      </div>
    </BlockingBackground>
  )
}
