import { ProjectCard } from '../ProjectCard/projectCard'
import React from 'react'
import './technologiesBlock.scss'
import { useNavigate } from 'react-router-dom'
import img1 from 'images/xgeo-technology-card.jpg'
import img2 from 'images/xlogi-technology-card.jpg'

interface ITechnologiesBlockProps extends React.HTMLAttributes<HTMLElement> {
  children?: any
}
export const TechnologiesBlock: React.FC<ITechnologiesBlockProps> = ({ children }) => {
  const navigate = useNavigate()
  const navigateTo = (value: string) => {
    navigate(`/technologies/${value}`)
  }
  return (
    <div className={'technologies-block__container container-transparent block-wrapper'}>
      <div className={'container__content container'}>
        <h1>Technologies</h1>
        <div className={'dark-text-color technologies-block__info normal-text'}>
          AI-based solutions for increasing business efficiency
        </div>
        <div className={'technologies-block__content'}>
          <ProjectCard
            name={'xgeo'}
            info={<>Software created for&nbsp;optimal road, power line, and&nbsp;gas pipeline development</>}
            pathLink={'xgeo'}
            navigateTo={navigateTo}
          >
            <img src={img1} />
          </ProjectCard>
          <ProjectCard
            name={'xlogi'}
            info={<>Software providing calculation of&nbsp;cost-efficient logistical solutions</>}
            pathLink={'xlogi'}
            navigateTo={navigateTo}
          >
            <img src={img2} />
          </ProjectCard>
        </div>
      </div>
    </div>
  )
}
