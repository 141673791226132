import React, { useEffect, useMemo, useState } from 'react'
import './casesBlock.scss'
import { CaseCard } from 'components/CaseCard/caseCard'
import { isDesktop, isTablet } from '../../../../utils'
import { DesktopImages } from './desktopImages'
import { TabletImages } from './tabletImages'
import { MobileImages } from './mobileImages'
import { useNavigate } from 'react-router-dom'

export const CasesBlock: React.FC = () => {
  const navigate = useNavigate()
  const [navigateTo, setNavigateTo] = useState<string>()
  const [img1, img2, img3, img4] = useMemo(() => {
    if (isDesktop()) return DesktopImages()
    if (isTablet()) return TabletImages()
    return MobileImages()
  }, [])
  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo)
    }
  }, [navigateTo])
  return (
    <div className={'cases-block__container container-transparent block-wrapper'}>
      <div className={'container__content container flex gap'}>
        <h1>Cases</h1>
        <div className={'cases-block__content'}>
          <CaseCard
            header={'OIL & GAS INDUSTRY'}
            infoText={'Cost of building gas pipelines was reduced by 17%'}
            linkPath={'cases/1'}
            navigateTo={setNavigateTo}
          >
            <img src={img1} />
          </CaseCard>{' '}
          <CaseCard
            header={'OIL & GAS INDUSTRY'}
            infoText={'Time required for road design for the field was reduced 4 times'}
            linkPath={'cases/2'}
            navigateTo={setNavigateTo}
          >
            <img src={img2} />
          </CaseCard>{' '}
          <CaseCard
            header={'OIL & GAS INDUSTRY'}
            infoText={'Cost of reengeneering gas field was reduced by 13%'}
            linkPath={'cases/3'}
            navigateTo={setNavigateTo}
          >
            <img src={img3} />
          </CaseCard>{' '}
          <CaseCard
            header={'MINING INDUSTRY'}
            infoText={'Cost of raw material logistics was reduced by 30%'}
            linkPath={'cases/4'}
            navigateTo={setNavigateTo}
          >
            <img src={img4} />
          </CaseCard>
        </div>
      </div>
    </div>
  )
}
