import React from 'react'
import classnames from 'classnames'
import './button.scss'
interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'secondary' | 'third-style' | 'fourth-style'
  children?: any
}

const Button: React.FC<IButton> = ({ mode = 'primary', className, children, ...props }) => {
  return (
    <button
      {...props}
      className={classnames(
        'main-button',
        'inter-font',
        className,
        mode,
        mode === 'secondary' && 'navigation-btn-text',
      )}
    >
      <div className='main-button__icon-wrapper'>{children}</div>
    </button>
  )
}

export default Button
