import React from 'react'
import './darkBlock.scss'
import classnames from 'classnames'

interface IDarkBlock extends React.HTMLAttributes<HTMLElement> {
  children?: any
}

export const DarkBlock: React.FC<IDarkBlock> = ({ children, ...props }) => {
  return <div className={classnames('dark-block__container', props.className)}>{children}</div>
}
