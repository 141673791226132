// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-device-width: 320px) {
  .third-block__container .dark-block__content {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(5, auto);
  }
}
@media only screen and (min-device-width: 768px) {
  .third-block__container .dark-block__content {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }
}
@media only screen and (min-device-width: 1366px) {
  .third-block__container .dark-block__content {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }
}
@media only screen and (min-device-width: 1920px) {
  .third-block__container .dark-block__content {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/components/ThirdBlock/thirdBlock.scss"],"names":[],"mappings":"AACA;EAEI;IACE,sCAAA;IACA,mCAAA;EADJ;AACF;AAKA;EAEI;IACE,sCAAA;IACA,mCAAA;EAJJ;AACF;AAQA;EAEI;IACE,sCAAA;IACA,mCAAA;EAPJ;AACF;AAWA;EAEI;IACE,sCAAA;IACA,mCAAA;EAVJ;AACF","sourcesContent":["\n@media only screen and (min-device-width: 320px) {\n  .third-block__container {\n    .dark-block__content {\n      grid-template-columns: repeat(1, auto);\n      grid-template-rows: repeat(5, auto);\n    }\n  }\n}\n\n@media only screen and (min-device-width: 768px) {\n  .third-block__container {\n    .dark-block__content {\n      grid-template-columns: repeat(2, auto);\n      grid-template-rows: repeat(2, auto);\n    }\n  }\n}\n\n@media only screen and (min-device-width: 1366px) {\n  .third-block__container {\n    .dark-block__content {\n      grid-template-columns: repeat(3, auto);\n      grid-template-rows: repeat(2, auto);\n    }\n  }\n}\n\n@media only screen and (min-device-width: 1920px) {\n  .third-block__container {\n    .dark-block__content {\n      grid-template-columns: repeat(3, auto);\n      grid-template-rows: repeat(2, auto);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
