import React from 'react'
import './header.scss'
import { ReactComponent as LogoIcon } from 'images/LogoIcon.svg'
import ArrowIcon from 'images/ArrowIcon'
import Button from 'components/Button/button'
import { HButton } from '../HButton/hButton'
// @ts-ignore
import { MenuButton } from 'components/MenuButton/MenuButton'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsDesktop, selectIsMobile, selectShowHeaderButtons, setBlockingWindow } from 'store/commonSlice'
import { BlockType } from 'Pages/types'
import { useNavigate } from 'react-router-dom'

interface IHeaderProps {
  setScrollTo(value: BlockType): void
}
export const Header: React.FC<IHeaderProps> = ({ setScrollTo }) => {
  const isMobile = useSelector(selectIsMobile)
  const isDesktop = useSelector(selectIsDesktop)
  const showHeaderButtons = useSelector(selectShowHeaderButtons)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div className={'header'}>
      <div className={'header__left'}>
        <>
          {!showHeaderButtons && <MenuButton setScrollTo={setScrollTo} />}
          <button onClick={() => navigate('/')}>
            <LogoIcon />
          </button>
          {showHeaderButtons && (
            <div className={'header__btns'}>
              <HButton onClick={() => setScrollTo('ABOUT')}>about</HButton>
              <HButton onClick={() => setScrollTo('CASES')}>cases</HButton>
              <HButton onClick={() => setScrollTo('DEMO')}>demo</HButton>
              <HButton onClick={() => setScrollTo('TECHNOLOGIES')}>technologies</HButton>
              <HButton onClick={() => setScrollTo('CONTACTS')}>contacts</HButton>
            </div>
          )}
        </>
      </div>
      {!isMobile && (
        <Button
          mode={'secondary'}
          onClick={() => {
            dispatch(
              setBlockingWindow({
                type: 'ContactForm',
              }),
            )
          }}
        >
          Contact us
          <ArrowIcon />
        </Button>
      )}
    </div>
  )
}
