import * as React from 'react'

function IconArrayDown(props: any) {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.4848 11.57C3.7498 12.305 3.7498 13.49 4.4848 14.225L16.9498 26.69C17.5348 27.275 18.4798 27.275 19.0648 26.69L31.5298 14.225C32.2648 13.49 32.2648 12.305 31.5298 11.57C30.7948 10.835 29.6098 10.835 28.8748 11.57L17.9998 22.43L7.1248 11.555C6.4048 10.835 5.2048 10.835 4.4848 11.57Z'
        fill='#1B1B1B'
      />
    </svg>
  )
}

export default IconArrayDown
