import * as React from 'react'

function ArrowVertIcon(props) {
  return (
    <svg width='35' height='81' viewBox='0 0 35 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3205 50.1055C13.8728 50.1055 14.3205 49.6578 14.3205 49.1055V1.10547C14.3205 0.553184 14.7682 0.105469 15.3205 0.105469H19.3205C19.8728 0.105469 20.3205 0.553183 20.3205 1.10547V49.1055C20.3205 49.6578 20.7682 50.1055 21.3205 50.1055H32.909C33.6788 50.1055 34.1599 50.9388 33.775 51.6055L18.1865 78.6055C17.8016 79.2721 16.8394 79.2721 16.4545 78.6055L0.866025 51.6055C0.481125 50.9388 0.962252 50.1055 1.73205 50.1055H13.3205Z'
        fill='#F75929'
      />
    </svg>
  )
}

export default ArrowVertIcon
