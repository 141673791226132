import React from 'react'
import './footer.scss'
import { ReactComponent as LogoIcon } from 'images/LogoIconFooter.svg'
import ArrowIcon from 'images/ArrowIcon'
import Button from 'components/Button/button'
import { HButton } from 'components/HButton/hButton'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
import { BlockType } from 'Pages/types'
interface IFooter {
  setScrollTo(value: BlockType): void
}
export const Footer: React.FC<IFooter> = ({ setScrollTo }) => {
  const dispatch = useDispatch()
  return (
    <div className={'footer__container block-wrapper'}>
      <div className={'footer__content container__content'}>
        <div className={'footer__header'}>
          <LogoIcon />
          <Button
            onClick={() => {
              dispatch(
                setBlockingWindow({
                  type: 'ContactForm',
                }),
              )
            }}
          >
            Contact us
            <ArrowIcon />
          </Button>
        </div>
        <div className={'footer__body'}>
          <HButton onClick={() => setScrollTo('ABOUT')}>about</HButton>
          <HButton onClick={() => setScrollTo('DEMO')}>demo</HButton>
          <HButton onClick={() => setScrollTo('TECHNOLOGIES')}>technologies</HButton>
          <HButton onClick={() => setScrollTo('CASES')}>cases</HButton>
          <HButton onClick={() => setScrollTo('CONTACTS')}>contacts</HButton>
        </div>
        <div className={'footer__text inter-font'}>© All rigths reserved 2024</div>
      </div>
    </div>
  )
}
