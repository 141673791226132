import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { LandingPage } from 'Pages/LandingPage/landingPage'
import { CasePage } from 'Pages/CasePage/casePage'
import { useCheckDeviceType } from './hooks/useCheckDeviceType'
import { TechnologyPage } from './Pages/TechnologyPage/technologyPage'

const App: React.FC = () => {
  useCheckDeviceType()
  const routerConfig = createRoutesFromElements(
    <Route>
      <Route path='/' element={<LandingPage />} />
      {/*<Route path='/cases/:caseId' element={<CasePage />} />*/}
      {/*<Route path={'/technologies/:technology'} element={<TechnologyPage />} />*/}
    </Route>,
  )
  const router = createBrowserRouter(routerConfig)
  return <RouterProvider router={router} />
}

export default App
