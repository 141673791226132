// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  display: flex;
  width: 100%;
  min-height: calc(100% - 23px - 3px);
  padding: 8px 12px;
  color: var(--web-primary-color);
  background: var(--white-color);
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 32px;
}
.textarea textarea::placeholder {
  color: var(--secondary-text-color);
}
.textarea:not(.disabled) {
  cursor: text;
}
.textarea:not(.disabled):hover {
  background-color: var(--white-hover-color);
}
.textarea:not(.disabled):active {
  background-color: var(--white-disabled-color);
}
.textarea:not(.disabled).focused {
  padding: 11px 20px;
  border: 2px solid var(--accent-color);
  background-color: var(--white-color);
}
.textarea.disabled {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--secondary-text-color);
  padding: 9.5px 9.5px 9.5px 8px;
}

.input-wrapper {
  position: relative;
}
.input-wrapper textarea {
  all: unset;
  min-width: 60px;
  flex: 1 1;
}

.input-wrapper__label {
  display: flex;
  align-items: center;
  gap: 3.5px;
  padding-bottom: 2px;
  color: var(--secondary-text-color);
}
.input-wrapper__label div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/TextArea/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mCAAA;EACA,iBAAA;EACA,+BAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;AACF;AACE;EACE,kCAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAEI;EACE,0CAAA;AAAN;AAEI;EACE,6CAAA;AAAN;AAEI;EACE,kBAAA;EACA,qCAAA;EACA,oCAAA;AAAN;AAKE;EACE,oCAAA;EACA,qCAAA;EACA,kBAAA;EACA,kCAAA;EACA,8BAAA;AAHJ;;AAOA;EACE,kBAAA;AAJF;AAME;EACE,UAAA;EACA,eAAA;EACA,SAAA;AAJJ;;AAUA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;EACA,mBAAA;EACA,kCAAA;AAPF;AASE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAPJ","sourcesContent":[".textarea{\n  display: flex;\n  width: 100%;\n  min-height: calc(100% - 23px - 3px);\n  padding: 8px 12px;\n  color: var(--web-primary-color);\n  background: var(--white-color);\n  border-radius: 6px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font-size: 20px;\n  line-height: 32px;\n\n  & textarea::placeholder {\n    color: var(--secondary-text-color)\n  }\n\n  &:not(.disabled) {\n    cursor: text;\n\n    &:hover {\n      background-color: var(--white-hover-color);\n    }\n    &:active {\n      background-color: var(--white-disabled-color);\n    }\n    &.focused {\n      padding: 11px 20px;\n      border: 2px solid var(--accent-color);\n      background-color: var(--white-color);\n    }\n\n  }\n\n  &.disabled {\n    background-color: var(--white-color);\n    border: 1px solid var(--border-color);\n    border-radius: 4px;\n    color: var(--secondary-text-color);\n    padding: 9.5px 9.5px 9.5px 8px;\n  }\n}\n\n.input-wrapper {\n  position: relative;\n\n  & textarea {\n    all: unset;\n    min-width: 60px;\n    flex: 1;\n  }\n}\n\n\n\n.input-wrapper__label {\n  display: flex;\n  align-items: center;\n  gap: 3.5px;\n  padding-bottom: 2px;\n  color: var(--secondary-text-color);\n\n  div:first-child {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
